import {FetchWs} from "@/functions/FetchWs";

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.grp_nome);
    const response = await FetchWs('grupo/register', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'grupos'});
    }

}