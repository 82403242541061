export default [
  [
    {
      col: 12,
      name: "login_email",
      customClass: "teste",
      value: "",
      type: "email",
      placeholder: "Ex.: nome@exemplo.com",
      label: "E-mail",
      leftIcon: "mail_outline",
      required: true
    }
  ],
  [
    {
      col: 12,
      name: "login_pass",
      value: "",
      type: "password",
      placeholder: "Ex: *******",
      label: "Senha",
      leftIcon: "key",
      required: true
    }
  ]
]