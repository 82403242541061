export default {
    name: "table-grupos",
    ignorePersonalizar: true,
    novoRoute: 'novo-grupo',
    columns: [
        { key: 'edit_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'grp_id', label: 'ID', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'grp_nome', label: 'Nome', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}