<template>
  <monta-crud :Crud="Crud">
  </monta-crud>
</template>

<script>
  import Crud from "./Crud";

  export default {
    props: [
      'id'
    ],
    data() {
      Crud.listTable.endpoint = 'cliente/get-contacts-list/' + this.$route.params.id
      Crud.newForm.data.endpoint ='cliente/novo-contato/' + this.$route.params.id
      return {
        Crud,
      }
    }
  }
</script>

<style scoped>
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}
</style>