import {GruposInterfaces} from "@/pages/painel/grupos/listagem/GruposInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any): Promise<void>
{
    vue.table.items = [];
    const response = await FetchWs('grupo/get-all');
    if(response && response.success && response.data.length > 0){
        vue.table.items = assertList(response.data);
    }
}

function assertList(objs: GruposInterfaces[]): GruposInterfaces[]
{
    objs.forEach((value:GruposInterfaces,key:number) => {
        objs[key].edit_id = value.grp_id
    })
    return objs;
}

export async function editPerms(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'grupos-editar-permissoes', params:{id: id}});
}

export async function deleteGrupo(id: number, vue:any): Promise<void>
{
    await FetchWs('grupo/delete/' + id, 'GET');
    vue.$router.push({path: '/'});
}

