import {FetchWs} from "@/functions/FetchWs";

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("situacao", data.cli_situacao);
    body.append("agencia", data.cli_agencia);
    body.append("tipo_pessoa", data.cli_tipo_pessoa);
    body.append("segmento", data.cli_segmento);
    body.append("consultor", data.cli_consultor);
    body.append("documento", data.cli_documento);
    body.append("contaazul_code", data.cli_contaazul_code);
    body.append("nome_fantasia", data.cli_nome_fantasia);
    body.append("razao_social", data.cli_razao_social);
    body.append("drive", data.cli_drive);
    body.append("logradouro", data.cli_logradouro);
    body.append("numero", data.cli_numero);
    body.append("complemento", data.cli_complemento);
    body.append("bairro", data.cli_bairro);
    body.append("cep", data.cli_cep);
    body.append("cidade", data.cli_cidade);
    body.append("estado", data.cli_estado);
    body.append("obs", data.cli_obs);
    const response = await FetchWs('cliente/register', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'clientes'});
    }

}