<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md6 lg6 xl6">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(edit_id)="{ source: edit_id }">
            <va-button icon="visibility" class="" @click="acoesModal = edit_id"/>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>

    <va-modal v-model="acoesModal">
      <div>
        <va-button class="btn-dropdown" icon-right="edit" color="#800080" gradient @click="editPerms(acoesModal, this)" size="medium">Permissões</va-button><br>
        <va-button class="btn-dropdown" icon-right="delete" color="danger" gradient @click="deleteModal = lastDeleteModalID = acoesModal;acoesModal = false;" size="medium">Excluir Grupo</va-button>
      </div>
    </va-modal>
    <va-modal v-model="deleteModal" message="Deseja realmente excluir este grupo?" ok-text="Excluir" @ok="deleteGrupo(lastDeleteModalID, this)" />
  </div>
</template>

<script>
import UsersTable from "./GruposTable";
import {refresh, deleteGrupo, editPerms} from "./GruposFunctions"

export default {
  name: "grupos",
  data() {
    return {
      table: UsersTable,
      deleteModal: false,
      acoesModal: false,
      lastDeleteModalID: false,
    }
  },
  methods:{
    editPerms,
    deleteGrupo,
    refresh
  }
}
</script>

<style scoped>
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}
</style>