<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md6 lg4 xl4">
      <va-card-content>
        <monta-form :form="form" @complete="(data)=>updatePass(data, this)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm";
import FormStruct from "./TrocaSenhaFormStruct";
import {updatePass} from './Functions';
export default {
  name: "TrocarSenha",
  components: {MontaForm},
  mounted(){
    this.id = this.$route.params.id;
    if(!this.id){
      this.$router.back()
    }
  },
  data() {
    return {
      form: {
        struct: FormStruct
      }
    }
  },
  methods:{
    updatePass
  },
}
</script>

<style scoped>

</style>