import {FetchWs} from "@/functions/FetchWs";

export async function updatePass(data: any, vue: any)
{
    const body = new FormData();
    body.append("id", vue.id);
    body.append("senha", data.register_pass);
    const response = await FetchWs('auth/changePass', 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}