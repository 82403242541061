<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md9 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-title>
        </va-card-title>
        <va-card-content>
          <monta-form :form="form" @complete="(data)=>submitFormNew(data, this)" />
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm";
import FormStruct from "./NovoClienteFormStruct"
import {submitFormNew} from "./NovoClienteFunctions"

export default {
  name: "NovoCliente",
  components: {MontaForm},
  data() {
    return {
      form: {
        struct: FormStruct
      },
    }
  },
  methods:{
    submitFormNew
  }
}
</script>

<style scoped>

</style>