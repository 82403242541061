import {isString} from "@/functions/Validations";

export default [
    [
        {
            col: 12,
            name: "grp_nome",
            value: "",
            type: "text",
            placeholder: "Ex.: Consultor",
            label: "Nome do Grupo",
            required: true,
            validateFunc: (val: any) => {
                if(!isString(val)) {
                    return false;
                }
                val = val.trim();
                if(val.length <= 4){
                    return 'Nome inválido';
                }
                return true;
            }
        }
    ]
]