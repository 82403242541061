export default {
    card:{
        lg: 12,
        xl: 12
    },
    listTable: {
        name: "table-clientes-contatos",
        idKey:'cco_id',
        novoModal: true,
        novoText: 'Novo',
        columns: [
            { key: 'acoes_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_nome', label: 'Nome', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_cargo', label: 'Cargo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_whatsapp', label: 'WhatsApp', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_fone', label: 'Fone', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_email', label: 'Email', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cco_obs', label: 'OBS', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        ],
        ignorePersonalizar: true,
    },
    newForm: {
        data: {
            closeButton: true,
        },
        struct: [
            [
                {
                    col: 12,
                    name: "cco_nome",
                    value: "",
                    type: "text",
                    label: "Nome",
                },
            ],
            [
                {
                    col: 12,
                    name: "cco_cargo",
                    value: "",
                    type: "text",
                    label: "Cargo",
                },
            ],
            [
                {
                    col: 12,
                    name: "cco_whatsapp",
                    value: "",
                    type: "text",
                    label: "WhatsApp",
                }
            ],
            [
                {
                    col: 12,
                    name: "cco_email",
                    value: "",
                    type: "text",
                    label: "Email",
                }

            ],[
                {
                    col: 12,
                    name: "cco_fone",
                    value: "",
                    type: "text",
                    label: "Fone",
                }
            ],[
                {
                    col: 12,
                    name: "cco_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                }
            ]
        ]
    },
    editForm: {
        data: {
            closeButton: true,
            endpoint: 'cliente/update-contato/:id'
        },
        struct: [
            [
                {
                    col: 12,
                    name: "cco_nome",
                    value: "",
                    type: "text",
                    label: "Nome",
                },
            ],
            [
                {
                    col: 12,
                    name: "cco_cargo",
                    value: "",
                    type: "text",
                    label: "Cargo",
                },
            ],
            [
                {
                    col: 12,
                    name: "cco_whatsapp",
                    value: "",
                    type: "text",
                    label: "WhatsApp",
                }
            ],
            [
                {
                    col: 12,
                    name: "cco_email",
                    value: "",
                    type: "text",
                    label: "Email",
                }

            ],[
                {
                    col: 12,
                    name: "cco_fone",
                    value: "",
                    type: "text",
                    label: "Fone",
                }
            ],[
                {
                    col: 12,
                    name: "cco_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                }
            ]
        ]
    },
    remove: {
        endpoint: 'cliente/excluir-contato/:id'

    }
}