import {FetchWs} from "@/functions/FetchWs";

export async function salvaPerms(vue: any){
    const perms = JSON.stringify(vue.selectedItems)
    const body = new FormData();
    body.append("perms", perms);
    const response = await FetchWs('user/update-perms/' + vue.idUser, 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}

export function getPermsActive(vue: any){
    return vue.perms.filter((perm:any) => perm.active)
}

export async function getPerms(vue: any) {
    const response = await FetchWs('user/get-all-perms/' + vue.idUser + '?edit=sim');
    if(response && response.data && Array.isArray(response.data)){
        vue.perms = response.data
    }
}



