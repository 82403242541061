import {validatePass} from "@/functions/Validations";

export default [
    [
        {
            col: 12,
            name: "register_pass",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Senha",
            leftIcon: "key",
            required: true,
            validateFunc: validatePass
        },
    ],
    [
        {
            col: 12,
            name: "register_pass_confirma",
            value: "",
            type: "password",
            placeholder: "Ex: *******",
            label: "Confirme a Senha",
            leftIcon: "key",
            required: true,
            validateFunc: (val: any, item: any, all: any) => {
                if(val !== all['register_pass']){
                    return 'Senhas diferentes';
                }
                return true;
            }
        }
    ]
]