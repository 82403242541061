export default [
    [
        {
            col: 4,
            name: "cli_situacao",
            value: "",
            type: "select",
            options: ["Ativo", "Pendente", "Inadimplente", "Cancelado"],
            label: "Situação",
            required: true,
            leftIcon: "fact_check",
        },
        {
            col: 4,
            name: "cli_agencia",
            value: "",
            options: ["Alper", "Promover"],
            type: "select",
            label: "Agência",
            leftIcon: "home_work",
            required: true
        },
        {
            col: 4,
            name: "cli_tipo_pessoa",
            value: "",
            options: ["PF", "PJ"],
            type: "select",
            label: "Tipo Pessoa",
            leftIcon: "corporate_fare",
        }
    ],
    [
        {
            col: 6,
            name: "cli_segmento",
            value: "",
            type: "text",
            label: "Segmento",
            leftIcon: "segment",
        },
        {
            col: 6,
            name: "cli_consultor",
            value: "",
            type: "text",
            label: "Consultor",
            leftIcon: "account_box",
        }
    ],
    [
        {
            col: 6,
            name: "cli_documento",
            value: "",
            type: "text",
            label: "Documento",
            leftIcon: "contact_page",
        },
        {
            col: 6,
            name: "cli_contaazul_code",
            value: "",
            type: "text",
            label: "ContaAzul Code",
            leftIcon: "vpn_key",
        }
    ],
    [
        {
            col: 6,
            name: "cli_nome_fantasia",
            value: "",
            type: "text",
            label: "Nome Fantasia",
            leftIcon: "store",
        },
        {
            col: 6,
            name: "cli_razao_social",
            value: "",
            type: "text",
            label: "Razao social",
            leftIcon: "store",
        }
    ],
    [
        {
            col: 12,
            name: "cli_drive",
            value: "",
            type: "text",
            label: "Link Drive",
            leftIcon: "add_to_drive",
        }
    ],
    [
        {
            col: 9,
            name: "cli_logradouro",
            value: "",
            type: "text",
            label: "Logradouro",
            leftIcon: "place",
        },
        {
            col: 3,
            name: "cli_numero",
            value: "",
            type: "text",
            label: "numero",
            leftIcon: "place",
        }
    ],
    [
        {
            col: 6,
            name: "cli_complemento",
            value: "",
            type: "text",
            label: "Complemento",
            leftIcon: "place",
        },
        {
            col: 6,
            name: "cli_bairro",
            value: "",
            type: "text",
            label: "Bairro",
            leftIcon: "place",
        }
    ],
    [
        {
            col: 4,
            name: "cli_cep",
            value: "",
            type: "text",
            label: "CEP",
            leftIcon: "home",
        },
        {
            col: 4,
            name: "cli_cidade",
            value: "",
            type: "text",
            label: "Cidade",
            leftIcon: "place",
        },
        {
            col: 4,
            name: "cli_estado",
            value: "",
            type: "text",
            label: "Estado",
            leftIcon: "place",
        }
    ],
    [
        {
            col: 12,
            name: "cli_obs",
            value: "",
            type: "textarea",
            label: "Obs",
            leftIcon: "keyboard",
        }
    ]
]