import {FetchWs} from "@/functions/FetchWs";

export default {
    card:{
        lg: 12,
        xl: 12
    },
    listTable: {
        name: "table-clientes-servicos",
        idKey:'cse_id',
        novoModal: true,
        novoText: 'Novo',
        columns: [
            { key: 'acoes_id', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'ser_nome', label: 'Serviço', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cse_valor_bruto', label: 'Valor Bruto', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cse_valor_liquido', label: 'Valor Liquido', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cse_qtd_limite', label: 'Qtd', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cse_data_inicio', label: 'Data de Início', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
            { key: 'cse_data_fim', label: 'Data de Fim', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        ],
        ignorePersonalizar: true,
    },
    newForm: {
        data: {
            closeButton: true,
        },
        customOptions: {
            'ser_nome': async function () {
                const response = await FetchWs("servicos/get-all-servicos");
                const data = response?.data;
                if(data && Array.isArray(data) && data.length > 0){
                    return data.map((v:any) => v.ser_nome)
                }
                return []
            },
        },
        struct: [
            [
                {
                    col: 12,
                    name: "ser_nome",
                    options: [],
                    type: "select",
                    label: "Serviço",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_qtd_limite",
                    value: "",
                    type: "text",
                    label: "Quantidade",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_valor_bruto",
                    value: "",
                    type: "text",
                    label: "Valor Bruto",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_valor_liquido",
                    value: "",
                    type: "text",
                    label: "Valor Liquido",
                    required: true
                }
            ],
            [
                {
                    col: 12,
                    name: "cse_data_inicio",
                    value: "",
                    type: "date",
                    label: "Data Início",
                    required: true
                }

            ],[
                {
                    col: 12,
                    name: "cse_data_fim",
                    value: "",
                    type: "date",
                    label: "Data Fim",
                    required: true
                }
            ],[
                {
                    col: 12,
                    name: "cse_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                }
            ]
        ]
    },
    editForm: {
        data: {
            closeButton: true,
            endpoint: 'cliente/update-servico/:id'
        },
        customOptions: {
            'ser_nome': async function () {
                const response = await FetchWs("servicos/get-all-servicos");
                const data = response?.data;
                if(data && Array.isArray(data) && data.length > 0){
                    return data.map((v:any) => v.ser_nome)
                }
                return []
            },
        },
        struct: [
            [
                {
                    col: 12,
                    name: "ser_nome",
                    options: [],
                    type: "select",
                    label: "Serviço",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_qtd_limite",
                    value: "",
                    type: "text",
                    label: "Quantidade",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_valor_bruto",
                    value: "",
                    type: "text",
                    label: "Valor Bruto",
                    required: true
                },
            ],
            [
                {
                    col: 12,
                    name: "cse_valor_liquido",
                    value: "",
                    type: "text",
                    label: "Valor Liquido",
                    required: true
                }
            ],
            [
                {
                    col: 12,
                    name: "cse_data_inicio",
                    value: "",
                    type: "date",
                    label: "Data Início",
                    required: true
                }

            ],[
                {
                    col: 12,
                    name: "cse_data_fim",
                    value: "",
                    type: "date",
                    label: "Data Fim",
                    required: true
                }
            ],[
                {
                    col: 12,
                    name: "cse_obs",
                    value: "",
                    type: "textarea",
                    label: "Obs",
                }
            ]
        ]
    },
    remove: {
        endpoint: 'cliente/excluir-servico/:id'

    }
}